<script>

export default {
  props: {
    logotype: {
      type: Object,
      required: true,
    },
  },
  data() {
    const lowResolutionImage = this.logotype.id
      && this.$toLitiumMediaUrl(this.logotype.id, { maxWidth: 50 });

    return {
      highResolutionImage: null,
      imgUrl: lowResolutionImage,
      isImageDownloaded: false,
    };
  },
  mounted() {
    if (!this.imgUrl) return;

    const container = this.$refs.imageContainer;
    const imageAspectRatio = (this.logotype.width / this.logotype.height) ?? 0;
    const containerAspectRatio = container.clientWidth / container.clientHeight;

    this.highResolutionImage = this.$toLitiumMediaUrl(this.logotype.id,
      {
        ...(imageAspectRatio < containerAspectRatio
          ? { maxWidth: container.clientWidth }
          : { maxHeight: container.clientHeight }),
      });

    this.$nextTick(() => {
      const img = new Image();

      img.onload = () => {
        this.imgUrl = this.highResolutionImage;
        this.isImageDownloaded = true;
      };

      img.src = this.highResolutionImage;
    });
  },
};
</script>

<template>
  <div class="logotype-image__container" ref="imageContainer">
    <div class="logotype-image__spacer"></div>
    <img
      :class="['logotype-image__image',
      {'logotype-image__image-loading' : !isImageDownloaded}]"
      :src="imgUrl"
    >
  </div>
</template>

<style>
  .logotype-image__container {
    position: relative;
  }

  .logotype-image__spacer {
    padding-top: 66.667%;
  }

  .logotype-image__image {
    object-position: center;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .logotype-image__image-loading {
    filter: blur(5px);
  }
</style>
